<template>
  <div class="packages-wrapper">
    <v-row class="ma-5 packages-page" justify="center">
      <v-col xl="3" lg="4" md="5" sm="10">
        <SinglePackage :single-package="annualPackage10" :key="annualPackage10.id" @planSelected="handleSelection" />
      </v-col>
      <v-col xl="3" lg="4" md="5" sm="10">
        <SinglePackage :single-package="annualPackage25" :key="annualPackage25.id" @planSelected="handleSelection" />
      </v-col>
      <v-col xl="3" lg="4" md="5" sm="10">
        <SinglePackage :single-package="annualPackage50" :key="annualPackage50.id" @planSelected="handleSelection" />
      </v-col>
    </v-row>

    <v-container class="d-flex flex-column align-center">
      <p>{{ $t('packages.warningOneAnnualPackage') }}</p>
      <div class="d-flex align-center">
        <a :href="websiteUrl + '#contact'" target="_blank" rel="noreferrer">
          <v-btn class="primary">{{ $t('buttons.sendMessage') }}</v-btn>
        </a>
        <span class="ml-2">{{ $t('packages.warningTwoAnnualPackage') }}</span>
      </div>
    </v-container>
  </div>
</template>

<script>
import SinglePackage from '@/components/SinglePackage';
import { initializePaddle } from '@paddle/paddle-js';
import i18n from '@/i18n/i18n';
import state from '@/store/index';
import { checkoutSuccessCallBack } from '@/mixins/paddle-functions';

export default {
  name: 'PackagesPage',
  components: { SinglePackage },
  data: () => ({
    organisationEmail: '',
    annualPackage10: {
      name: 'packages.annualPackageType10',
      price: '',
      price_id: null,
      id: 9,
      disable: false,
      annualPackagesMessage: 'packages.annualPackagesMessage',
      mainMessage: 'packages.annualPackageType10MainMessage',
      listItems: [
        'packages.allFeaturesIncluded',
        'packages.unlimitedBookings',
        'packages.unlimitedVehicles',
        'packages.trialPeriodIncluded',
        'packages.onetimePaymentAnnually',
      ],
      currency: 'EUR',
    },
    annualPackage25: {
      name: 'packages.annualPackageType25',
      price: '',
      price_id: null,
      id: 10,
      disable: false,
      annualPackagesMessage: 'packages.annualPackagesMessage',
      mainMessage: 'packages.annualPackageType25MainMessage',
      listItems: [
        'packages.allFeaturesIncluded',
        'packages.unlimitedBookings',
        'packages.unlimitedVehicles',
        'packages.trialPeriodIncluded',
        'packages.onetimePaymentAnnually',
      ],
      currency: 'EUR',
    },
    annualPackage50: {
      name: 'packages.annualPackageType50',
      price: '',
      price_id: null,
      id: 11,
      disable: false,
      annualPackagesMessage: 'packages.annualPackagesMessage',
      mainMessage: 'packages.annualPackageType50MainMessage',
      listItems: [
        'packages.allFeaturesIncluded',
        'packages.unlimitedBookings',
        'packages.unlimitedVehicles',
        'packages.trialPeriodIncluded',
        'packages.onetimePaymentAnnually',
      ],
      currency: 'EUR',
    },
    planChangeDialog: false,
    selectedPlan: {},
    demoMessage: '',
    quantity: null,
    paddle: null,
    websiteUrl: process.env.VUE_APP_WEBSITE_URL,
  }),
  created() {
    this.loadAllPricingRegions();
    let config = {
      token: process.env.VUE_APP_PADDLE_CLIENT_SIDE_TOKEN,
    };

    if (process.env.VUE_APP_ENV !== 'production') {
      config.environment = 'sandbox';
    }

    initializePaddle({
      ...config,
      eventCallback: (data) => {
        checkoutSuccessCallBack(data, this.$store);
      },
    }).then((paddleInstance) => {
      if (paddleInstance) {
        this.paddle = paddleInstance;
      }
    });
  },
  methods: {
    loadAllPricingRegions() {
      let activeSubscriptionType = parseInt(this.$store.getters['auth/subscription']?.subscriptionType?.id);
      if (activeSubscriptionType === 4) {
        this.demoMessage = 'packages.warningThree';
      }
      this.$store.dispatch('subscriptionPackages/getAllPricingRegions').then((res) => {
        this.annualPackage10.price = res.team_10_price;
        this.annualPackage10.price_id = res.team_10_paddle_price_id;
        this.annualPackage10.currency = res.currency;
        this.annualPackage25.price = res.team_25_price;
        this.annualPackage25.price_id = res.team_25_paddle_price_id;
        this.annualPackage25.currency = res.currency;
        this.annualPackage50.price = res.team_50_price;
        this.annualPackage50.price_id = res.team_50_paddle_price_id;
        this.annualPackage50.currency = res.currency;
      });
    },
    handleSelection(value) {
      this.organisationEmail = this.$store.state.auth.user.organisation.email;
      if (value === this.annualPackage10.id) {
        this.selectedPlan = Object.assign({}, this.annualPackage10);
      } else if (value === this.annualPackage25.id) {
        this.selectedPlan = Object.assign({}, this.annualPackage25);
      } else if (value === this.annualPackage50.id) {
        this.selectedPlan = Object.assign({}, this.annualPackage50);
      }
      this.checkPackageChangeAvailability();
    },
    async checkPackageChangeAvailability() {
      await this.$store
        .dispatch('subscriptionPackages/checkPackageChangeAvailability', { id: this.selectedPlan.id })
        .then((response) => {
          if (response.success) {
            this.openPaddleModal();
          } else {
            this.$store.dispatch('showSnackbar', { text: i18n.t('snackbar.anErrorOccured'), color: 'red' });
          }
        })
        .catch((error) => {
          if (error.response?.status === 422) {
            this.$store
              .dispatch('errorMessages/errorMapper', error.response.data.message)
              .then((e) => this.$store.dispatch('showSnackbar', { text: e, color: 'red' }));
          } else {
            this.$store.dispatch('showSnackbar', { text: i18n.t('snackbar.anErrorOccured'), color: 'red' });
          }
        });
    },
    async openPaddleModal() {
      if (this.paddle && this.selectedPlan.price_id && [5, 6, 7, 8, 9, 10, 11].includes(this.selectedPlan.id)) {
        try {
          this.paddle.Checkout.open({
            settings: {
              displayMode: 'overlay',
              theme: 'light',
              allowLogout: false,
            },
            items: [
              {
                priceId: this.selectedPlan.price_id,
              },
            ],
            customer: {
              email: this.organisationEmail,
            },
          });
        } catch (error) {
          // eslint-disable-next-line no-console
          console.error('Error fetching data:', error);
        }
      }
    },
    async changeSubscription() {
      await this.$store
        .dispatch('subscriptionPackages/changePackage', { subscription_type_id: this.selectedPlan.id })
        .then(async (response) => {
          if (response.success) {
            state.dispatch('auth/setUser', {
              ...this.$store.state.auth.user,
              organisation: response.organisation,
            });
            localStorage.setItem(
              'LimoExpressUser',
              JSON.stringify({
                ...this.$store.state.auth.user,
                organisation: response.organisation,
              })
            );
            this.$store.dispatch('auth/setSubscription', response.data);
            state.dispatch('auth/setSubscriptionTypeId', response.subscription_type_id);
            localStorage.setItem('subscription', response.data ? JSON.stringify(response.data) : '');
            localStorage.setItem('subscription_type_id', response.subscription_type_id);
            this.$store.dispatch('showSnackbar', { text: i18n.t('snackbar.planChanged'), color: 'green' });
          }
        });
    },
  },
  watch: {
    '$store.state.auth.paddleState': {
      handler() {
        this.changeSubscription();
      },
    },
  },
};
</script>

<style scoped>
.packages-wrapper {
  position: relative;
  top: 50%;
  transform: translateY(-50%);
}

.plan-name {
  font-size: 1.2rem;
  font-weight: bold;
}

.selected-button {
  color: var(--primary);
  border: 1px solid var(--primary);
  border-radius: 8px;
}

.not-selected-button {
  background-color: transparent !important;
  border: none;
  color: #333333 !important;
  box-shadow: none;
}
</style>
